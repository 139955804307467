<template>
  <div class="bg global-blue">
    <v-container>
      <v-row>
        <v-col>
          <!-- <p class="my-8 global-title">{{ translations.title[locale] }}</p> -->
          <h1 class="global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</h1>
          <h1 class="global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col>
          <template v-for="(r,i) in references">
            <v-card
              v-if="r.g.length > 0 && r.g.find(a => a.answer[locale] != null ) != null"
              class="my-4 ref"
              outlined
              dark
              :key="i"
            >
              <v-container>
                <v-row>
                  <v-col cols="10">
                    <div
                      v-for="(input, index) in r.g"
                      :key="index"
                    >
                      <p
                        class="title-ref"
                        v-if="input.tag == 'referencename' && input.answer != null && input.answer[locale] != null"
                      >{{ input.answer[locale] }}</p>
                      <p
                        class="text-ref"
                        v-if="input.tag == 'referencedetail' && input.answer != null && input.answer[locale] != null"
                      >{{ input.answer[locale] }}</p>

                    </div>
                  </v-col>

                </v-row>
                <router-link :to="{ name: 'environments' }"></router-link>
                <router-link :to="{ name: 'environmentView', params: { id: r.questionnaire }}">{{ r.localizations != null ? r.localizations.name[locale] : r.name }}</router-link>

              </v-container>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <error></error>
  </div>
</template>

<style scoped>
.ref {
  background: var(--v-primary-base) !important;
}
.title-ref {
  color: #f7941e;
  font-size: 22px;
}
.environment-name {
  color: #00aeef;
}
.global-title {
  text-align: left;
}
.bg {
  height: 100%;
  padding-bottom: 200px;
}
</style>

<script>
import service from "../../services/references";
import { PORTAL_REFERENCES } from "../../constants/translations";

export default {
  name: "References",
  components: {
    Error: () => import("../../components/Error"),
  },
  data() {
    return {
      references: [],
      loading: true,
      translations: PORTAL_REFERENCES,
    };
  },
  methods: {
    storage(key, data) {
        if (!data) {
          const cache = window.localStorage.getItem(key);
          if (cache) return cache
          return;
        } else {
          window.localStorage.setItem(key, data);
        }
    },
  },
  created() {
    const referencesCache = this.storage("references_portal");
    if (referencesCache) {
      this.references = JSON.parse(referencesCache).ref;
      this.loading = false;
    }

    service
      .getInstance()
      .findAllReferences()
      .then((refs) => {
        this.references = [...refs];

        this.storage("references_portal", JSON.stringify({ref:this.references}));

        this.loading = false;
      })
      .catch((err) => {
        // console.log(err);
      });
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
};
</script>
import axios from '../axios'

const ReferenceService = (function() {
  let instance;
  function Service() {}

  Service.prototype.findAllReferences = function() {
    return axios
      .get(`references`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


  return {
    getInstance: () => {
      if (!instance) {
        instance = new Service();
      }

      return instance;
    }
  };
})();

export default ReferenceService;
